/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
 background-color:#B6000F ;
.custom-popover {
      .popover-content{  
         inline-size:350px ;
         overflow: visible;                
      }
      .select-interface-option:hover{        
        --color: #737373;
        --color-hover: #737373;
      }      
        // ion-radio {
        //     --color-checked: #75D154;
         
        // }
      }
      .custom-popoverweb {
        .popover-content  {     
           // --width: 75% !important;
            width:30vw;          
        }
        // .select-interface-option:hover{        
        //   --color: #737373;
        //   --color-hover: #737373;
        // }      
          // ion-radio {
          //     --color-checked: #75D154;
           
          // }
        } 
 }

 .swiper-pagination {
   bottom: 5.5vh !important;   
  
}
.swiper-pagination-bullet {
  background:#ffffff ;
 opacity: 1;
 
}
.swiper-pagination-bullet-active{
  background:#B6000F ;
  width: 11px;
  height:11px;
}
.ion-app {
  --background:   #B6000F;
  padding: 0;
}
.toast-scheme-active { 
   --background: gray  !important ;
    text-align: center;
   
}

.toast-scheme { 
    --background: gray  !important ;
     text-align: center;
    
 }
 .red {
  color: var(--suite-color-danfoss-red);
}

ion-textarea div{
  height: 15vh !important;
}
.content-notch {
  margin-top: env(safe-area-inset-top);
}
ion-popover.popover_setting {
  div.popover-content {
      top: 42% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: 85% !important;
}
}
*:enabled{
      cursor: auto;
 }
ion-card{
   cursor: pointer;
 }
 delta-sidebar-button{
  ion-item{
    ion-fab-button{
      margin-inline: 6px;
     ion-icon{
      font-size: 24px;
     }
    }
    ion-label{
      margin: 2px;
    }
  }
 }
 .custom-alert {
  .alert-title {
    text-align: center;
  }
 
  .alert-button-inner{
  text-decoration: underline;
}
}